import { SectorModelFragment, useUpdateSectorMutation } from "@/graphql/types";
import { trim } from "@/graphql/utils/utils";
import { useToast } from "primevue/usetoast";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useValidation } from "vue3-form-validation";

export const useUpdateSector = () => {
  const visible = ref(false);
  const toast = useToast();
  const { t } = useI18n();
  const { loading, mutate, onError, onDone } = useUpdateSectorMutation();
  const { form, hasError, validateFields, resetFields } = useValidation({
    id: {
      $value: 0,
      $rules: [(n: number) => !n && ""],
    },
    name: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    parentId: {
      $value: null
    }
  });
  const disableSubmit = computed(() => hasError.value || loading.value);

  onDone(({ errors }) => {
    if (errors) {
      toast.add({
        severity: "warn",
        summary: t("update.title"),
        detail: t("update.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    }
    visible.value = false;
  });

  onError((err) => {
    console.log(err);
    toast.add({
      severity: "warn",
      summary: t("update.title"),
      detail: t("update.failed"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  const close = () => (visible.value = false);
  const openUpdate = (data: SectorModelFragment) => {
    resetFields(data);
    visible.value = true;
  };

  function submit() {
    validateFields().then(async (input) => {
      trim(input);
      console.log(input);
      void mutate({ input });
    });
  }

  return {
    form,
    disableSubmit,
    loading,
    visible,
    close,
    submit,
    openUpdate,
  };
};
