import { useCreateSectorMutation } from "@/graphql/types";
import { trim } from "@/graphql/utils/utils";
import { useToast } from "primevue/usetoast";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useValidation } from "vue3-form-validation";

export const useCreateSector = () => {
  const visible = ref(false);
  const toast = useToast();
  const { t } = useI18n();
  const { loading, mutate, onError, onDone } = useCreateSectorMutation({
    update: (cache, { data }) => {
      if (data?.createSector) {
        cache.modify({
          fields: {
            sectors(value, { toReference }) {
              return [toReference(data.createSector), ...value];
            },
          },
        });
      }
    },
  });
  const { form, hasError, validateFields, resetFields } = useValidation({
    name: {
      $value: "",
      $rules: [(n: string) => !n && ""],
    },
    parentId: {
      $value: null
    }
  });
  const disableSubmit = computed(() => hasError.value || loading.value);

  onDone(({ errors }) => {
    toggle();
    if (errors) {
      toast.add({
        severity: "warn",
        summary: t("create.title"),
        detail: t("create.failed"),
        life: parseInt(process.env.VUE_APP_TOAST_LIFE),
      });
    } else resetFields();
  });

  onError((err) => {
    console.log(err);
    toast.add({
      severity: "warn",
      summary: t("create.title"),
      detail: t("create.failed"),
      life: parseInt(process.env.VUE_APP_TOAST_LIFE),
    });
  });

  const toggle = () => (visible.value = !visible.value);

  function submit() {
    validateFields().then(async (input) => {
      trim(input);
      void mutate({ input });
    });
  }

  return {
    form,
    disableSubmit,
    loading,
    visible,
    toggle,
    submit,
  };
};
